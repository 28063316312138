import { default as TemplatePageqgG472VJ4ZMeta } from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.657_encoding@0.1.13_ioredis@5.3.2_rollup@4.14.1_typescript@5.4.5_vue@3.4.21_typescript@5.4.5_/node_modules/@edco/apollo/dist/runtime/pages/TemplatePage.vue?macro=true";
import { default as KitchenSinkUuCu4wHF4gMeta } from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.657_encoding@0.1.13_ioredis@5.3.2_rollup@4.14.1_typescript@5.4.5_vue@3.4.21_typescript@5.4.5_/node_modules/@edco/apollo/dist/runtime/pages/KitchenSink.vue?macro=true";
import { default as BackofficeComponentPreviewn0kbbXt8SDMeta } from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.657_encoding@0.1.13_ioredis@5.3.2_rollup@4.14.1_typescript@5.4.5_vue@3.4.21_typescript@5.4.5_/node_modules/@edco/apollo/dist/runtime/pages/BackofficeComponentPreview.vue?macro=true";
import { default as ReadyProbeje3ybWQcLIMeta } from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.657_encoding@0.1.13_ioredis@5.3.2_rollup@4.14.1_typescript@5.4.5_vue@3.4.21_typescript@5.4.5_/node_modules/@edco/apollo/dist/runtime/pages/ReadyProbe.vue?macro=true";
export default [
  {
    name: TemplatePageqgG472VJ4ZMeta?.name ?? "template-page",
    path: TemplatePageqgG472VJ4ZMeta?.path ?? "/:slug(.*)*",
    meta: { ...(TemplatePageqgG472VJ4ZMeta || {}), ...{"middleware":["headless-middleware"]} },
    alias: TemplatePageqgG472VJ4ZMeta?.alias || [],
    redirect: TemplatePageqgG472VJ4ZMeta?.redirect,
    component: () => import("/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.657_encoding@0.1.13_ioredis@5.3.2_rollup@4.14.1_typescript@5.4.5_vue@3.4.21_typescript@5.4.5_/node_modules/@edco/apollo/dist/runtime/pages/TemplatePage.vue").then(m => m.default || m)
  },
  {
    name: KitchenSinkUuCu4wHF4gMeta?.name ?? "kitchen-sink",
    path: KitchenSinkUuCu4wHF4gMeta?.path ?? "/_kitchen-sink",
    meta: { ...(KitchenSinkUuCu4wHF4gMeta || {}), ...{"layout":false,"pageTransition":false,"layoutTransition":false} },
    alias: KitchenSinkUuCu4wHF4gMeta?.alias || [],
    redirect: KitchenSinkUuCu4wHF4gMeta?.redirect,
    component: () => import("/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.657_encoding@0.1.13_ioredis@5.3.2_rollup@4.14.1_typescript@5.4.5_vue@3.4.21_typescript@5.4.5_/node_modules/@edco/apollo/dist/runtime/pages/KitchenSink.vue").then(m => m.default || m)
  },
  {
    name: BackofficeComponentPreviewn0kbbXt8SDMeta?.name ?? "umb-backoffice-component-preview",
    path: BackofficeComponentPreviewn0kbbXt8SDMeta?.path ?? "/umb-backoffice-component-preview",
    meta: { ...(BackofficeComponentPreviewn0kbbXt8SDMeta || {}), ...{"layout":false,"pageTransition":false,"layoutTransition":false} },
    alias: BackofficeComponentPreviewn0kbbXt8SDMeta?.alias || [],
    redirect: BackofficeComponentPreviewn0kbbXt8SDMeta?.redirect,
    component: () => import("/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.657_encoding@0.1.13_ioredis@5.3.2_rollup@4.14.1_typescript@5.4.5_vue@3.4.21_typescript@5.4.5_/node_modules/@edco/apollo/dist/runtime/pages/BackofficeComponentPreview.vue").then(m => m.default || m)
  },
  {
    name: ReadyProbeje3ybWQcLIMeta?.name ?? "nuxt-ready-probe",
    path: ReadyProbeje3ybWQcLIMeta?.path ?? "/_probes/nuxt/ready",
    meta: { ...(ReadyProbeje3ybWQcLIMeta || {}), ...{"layout":false,"pageTransition":false,"layoutTransition":false} },
    alias: ReadyProbeje3ybWQcLIMeta?.alias || [],
    redirect: ReadyProbeje3ybWQcLIMeta?.redirect,
    component: () => import("/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.657_encoding@0.1.13_ioredis@5.3.2_rollup@4.14.1_typescript@5.4.5_vue@3.4.21_typescript@5.4.5_/node_modules/@edco/apollo/dist/runtime/pages/ReadyProbe.vue").then(m => m.default || m)
  }
]